import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { $t } from '@/i18n';
import { computed, onMounted, ref, toRefs } from 'vue';
import {
  DueSoonOrderList,
  InProgressOrderList,
  NoOrdersFallback,
  OrderActionStatus,
  OrderFilter,
  OrderLocalStatus,
  PausedOrderList,
  useFiltersAndTabs,
  useInProgressOrders,
} from '@/features/orders';
import { useOrdersOverview } from '@/features/orders/composables/useOrdersOverview';
import { usePickingOrders } from '@/features/orders/composables/usePickingOrders';
import OpenOrderList from '@/features/orders/components/OpenOrderList.vue';
import type { StorageSortDirection } from '@/features/core/storage';


export default /*@__PURE__*/_defineComponent({
  __name: 'PickingOrdersList',
  props: {
  disabledProcess: {
    type: Boolean,
    default: false,
  },
  isSearchActive: {
    type: Boolean,
    default: false,
  },
  searchFocused: {
    type: Boolean,
    default: false,
  },
  showBanner: {
    type: Boolean,
    default: false,
  },
  filteringStatus: {
    type: Object,
    default: () => ({}),
  },
},
  setup(__props) {

const props = __props;

const {
  disabledProcess,
  isSearchActive,
  searchFocused,
  showBanner,
  filteringStatus,
} = toRefs(props);

const {
  pickingInProgressOrders,
  isPickingInProgressOrdersVisible,
  changeInProgressOrdersVisible,
  loadOrdersInProgress,
} = useInProgressOrders();

const { pausedOrders, dueSoon, openOrders, orders, loadOrders } =
  usePickingOrders();

const { temperatureClasses, clearFilter, updateFilterTempClasses, currentTab } =
  useFiltersAndTabs();

const onChangeFilter = async (
  listOrder: string[],
  sortBy: string,
  sort: StorageSortDirection,
  pickingInProgressOrdersToggled: boolean,
) => {
  changeInProgressOrdersVisible(pickingInProgressOrdersToggled);
  updateFilterTempClasses(listOrder);

  filteringStatus.value.temperatureClasses = listOrder;
  filteringStatus.value.sortBy =
    listOrder.length === 1 && sortBy === 'itemsCount.total'
      ? `itemsCount.${listOrder[0]}`
      : sortBy;
  filteringStatus.value.sort = sort;

  if (pickingInProgressOrdersToggled) {
    await loadOrdersInProgress({
      ...filteringStatus.value,
      status: currentTab.value.status,
      localStatus: [OrderLocalStatus.PickingInProgress],
    });
  }
  if (currentTab.value.status === OrderActionStatus.picking) {
    await loadOrders(filteringStatus.value);
  }

  window?.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

const orderFilter = ref<null | typeof OrderFilter>(null);
const resetFilters = async () => {
  clearFilter();
  if (orderFilter.value) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    orderFilter.value.clearFilter();
  }
  await loadOrders(filteringStatus.value);
};

const { fallbackType } = useOrdersOverview();

const isNoOrdersFallbackVisible = computed(
  () =>
    !pickingInProgressOrders.value.length &&
    !pausedOrders.value.length &&
    !dueSoon.value.length &&
    !openOrders.value.length,
);

const ordersTitle = computed(() => {
  if (isSearchActive.value || !orders.value.length) {
    return $t('components.search-result-feedback.results.text');
  }
  return $t('pages.order-overview.open-orders.text');
});

onMounted(async () => {
  await loadOrders(filteringStatus.value);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(InProgressOrderList), {
      disabledProcess: _unref(disabledProcess),
      pickingInProgressOrders: _unref(pickingInProgressOrders),
      isPickingInProgressOrdersVisible: _unref(isPickingInProgressOrdersVisible)
    }, null, 8, ["disabledProcess", "pickingInProgressOrders", "isPickingInProgressOrdersVisible"]),
    _createVNode(_unref(PausedOrderList), {
      disabledProcess: _unref(disabledProcess),
      pausedOrders: _unref(pausedOrders)
    }, null, 8, ["disabledProcess", "pausedOrders"]),
    _createVNode(_unref(DueSoonOrderList), {
      disabledProcess: _unref(disabledProcess),
      "due-soon-orders": _unref(dueSoon)
    }, null, 8, ["disabledProcess", "due-soon-orders"]),
    _createVNode(OpenOrderList, {
      disabledProcess: _unref(disabledProcess),
      "open-orders": _unref(openOrders),
      ordersTitle: ordersTitle.value
    }, null, 8, ["disabledProcess", "open-orders", "ordersTitle"]),
    (!_unref(isSearchActive) && isNoOrdersFallbackVisible.value)
      ? (_openBlock(), _createBlock(_unref(NoOrdersFallback), {
          key: 0,
          fallbackType: _unref(fallbackType),
          onButtonClick: resetFilters
        }, null, 8, ["fallbackType"]))
      : _createCommentVNode("", true),
    (!_unref(searchFocused) && !_unref(isSearchActive))
      ? (_openBlock(), _createBlock(_unref(OrderFilter), {
          key: 1,
          ref_key: "orderFilter",
          ref: orderFilter,
          "filter-sorting": {
      sortBy: _unref(filteringStatus)?.sortBy,
      sort: _unref(filteringStatus)?.sort,
    },
          "is-banner-active": _unref(showBanner),
          typesOfItem: _unref(temperatureClasses),
          isPickingInProgressOrdersVisible: _unref(isPickingInProgressOrdersVisible),
          onChangeFilter: onChangeFilter
        }, null, 8, ["filter-sorting", "is-banner-active", "typesOfItem", "isPickingInProgressOrdersVisible"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})