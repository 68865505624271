import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  key: 1,
  class: "order-list__orders"
}
const _hoisted_3 = {
  key: 2,
  class: "order-list__orders"
}

import {
  AppHeader,
  Badge,
  CustomerNotePopup,
  Dialog,
  ScreenLoading,
  Search,
  Tab,
} from '@/features/ui/components';
import { $t } from '@/i18n';
import { onMounted, ref, watch } from 'vue';
import type { RouteLocationNormalized } from 'vue-router';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import type { SearchComponent } from '../types';
import { tabs, tabsList } from '../types';
import { PickingOrdersList, HandoverOrdersList } from '../views';
import {
  PullToRefresh,
  SearchedOrderList,
  SearchResultFeedback,
} from '../components';
import {
  useCheckInOrders,
  useOnlineStatus,
  usePickingOrders,
  useFiltersAndTabs,
  useOrdersOverview,
  useOrderSearch,
} from '@/features/orders/composables';
import {
  CheckInBanner,
  CheckInBannerContextEnum,
} from '@/features/check-in-banner';
import { useTransmissions } from '@/features/transmissions';
import { usePerformanceTracker } from '@/features/performance-tracker';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';


//TODO <script setup> notation doesnt support beforeRouteEnter hook
import { defineComponent } from 'vue';
import { useRedirectedGuard } from '@/features/orders/composables';

const __default__ = defineComponent({
  beforeRouteEnter(to, from, next) {
    const { redirectGuard } = useRedirectedGuard();
    redirectGuard(next);
  },
});

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'OrdersOverview',
  setup(__props) {

const {
  firstCheckedInOrder,
  checkinOrdersLength,
  showBanner,
  checkCheckedInOrders,
} = useCheckInOrders();

const {
  isPageLoadingVisible,
  screenLoadingTitle,
  isRefreshLoading,
  disabledProcess,
  isNotePopupVisible,
  interactedOrder,
  toggleNote,
  fetchNewOrders,
  cardsRefs,
  isOnline,
} = useOrdersOverview();

const {
  search,
  isSearchActive,
  searchTitle,
  isSearching,
  searchFocused,

  toggleSearch,
  activatedSearch,
  setBarcodeReader,
  searchedOrders,
  searchOrdersByValues,
} = useOrderSearch();

const { handleRoute } = useRedirectedGuard();

watch(search, (newValue) => {
  void searchOrdersByValues(newValue, filteringStatus.value);
});

const searchRef = ref<null | SearchComponent>(null);
setBarcodeReader(searchRef);

const { filteringStatus, currentTab, isHandoverTab } = useFiltersAndTabs();

const { newOrdersLength, pickingBuffer } = usePickingOrders();

const {
  skipTransmission,
  toggleTransmissionsWindowVisibility,
  isTransmissionsModalVisible,
  initTransmissionsCheck,
} = useTransmissions();

const { stopTracking } = usePerformanceTracker();

onMounted(() => {
  void checkCheckedInOrders();
  stopTracking('bags-position-to-order-list');
  stopTracking('handover-order-to-order-list');
  initTransmissionsCheck();
});

const { setOnlineStatusHooks } = useOnlineStatus();
setOnlineStatusHooks();

//#region scroll to order
const route = useRoute();
watch(cardsRefs, () => {
  void handleRoute(route, cardsRefs).then((orderId: string | null) => {
    if (orderId !== null && isHandoverTab.value && isOnline.value) {
      void fetchNewOrders([orderId]);
    }
  });
});

watch(
  () => route,
  (to: RouteLocationNormalized, from: RouteLocationNormalized | undefined) => {
    //During initial page loading the cardsRefs watcher will handle the route
    if (from === undefined) {
      return;
    }

    void handleRoute(to, cardsRefs).then((orderId: string | null) => {
      if (orderId !== null && isHandoverTab.value && isOnline.value) {
        void fetchNewOrders([orderId]);
      }
    });
  },
  { deep: true },
);
//#endregion

const handleBeforeRouteLeave = (to: RouteLocationNormalized): boolean => {
  if (to.meta?.preventRedirectFromOrdersList) {
    notificationPlugin.get().show({
      text: $t('pages.order-overview.cant-go-back.text'),
      type: NotificationType.Error,
      duration: 2000,
    });
    return false;
  }

  return true;
};

onBeforeRouteLeave((to: RouteLocationNormalized) => handleBeforeRouteLeave(to));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(AppHeader), {
      class: _normalizeClass({
      'header__search--active': _unref(isSearchActive),
    }),
      "border-bottom": "",
      fixed: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_unref(Search), {
          ref_key: "searchRef",
          ref: searchRef,
          modelValue: _unref(search),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(search) ? (search).value = $event : null)),
          placeholderTitle: 
        _unref(isHandoverTab)
          ? _unref($t)('components.search.placeholder.handover')
          : _unref($t)('components.search.placeholder.picking')
      ,
          searchTitle: _unref(searchTitle),
          inputMode: "numeric",
          onFocus: _unref(toggleSearch),
          onSearchActivated: _unref(activatedSearch)
        }, null, 8, ["modelValue", "placeholderTitle", "searchTitle", "onFocus", "onSearchActivated"]),
        (!_unref(isSearchActive))
          ? (_openBlock(), _createBlock(_unref(Tab), {
              key: 0,
              "active-tab": _unref(currentTab),
              "link-list": _unref(tabsList),
              class: "tab__wrap"
            }, {
              badge: _withCtx(({ linkTo }) => [
                (linkTo === _unref(tabs).Picking.linkTo && _unref(newOrdersLength))
                  ? (_openBlock(), _createBlock(_unref(Badge), {
                      key: 0,
                      text: _unref(newOrdersLength).toString()
                    }, null, 8, ["text"]))
                  : _createCommentVNode("", true),
                (linkTo === _unref(tabs).Handover.linkTo && _unref(checkinOrdersLength))
                  ? (_openBlock(), _createBlock(_unref(Badge), {
                      key: 1,
                      variant: "alert",
                      text: _unref(checkinOrdersLength).toString()
                    }, null, 8, ["text"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["active-tab", "link-list"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class"]),
    _createElementVNode("section", _hoisted_1, [
      (_unref(isPageLoadingVisible))
        ? (_openBlock(), _createBlock(_unref(ScreenLoading), {
            key: 0,
            title: _unref(screenLoadingTitle),
            blur: "",
            fixed: "",
            inFront: ""
          }, null, 8, ["title"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass([
        'orders-list',
        {
          'orders-list--short': _unref(isSearchActive),
          'orders-list--with-banner': _unref(showBanner),
        },
      ]),
        "data-e2e": "orders-list"
      }, [
        true
          ? (_openBlock(), _createBlock(_unref(PullToRefresh), {
              key: 0,
              loading: _unref(isRefreshLoading),
              trigger: ".container",
              onRefresh: _unref(fetchNewOrders)
            }, null, 8, ["loading", "onRefresh"]))
          : _createCommentVNode("", true),
        (!_unref(isSearchActive))
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (!_unref(isHandoverTab))
                ? (_openBlock(), _createBlock(_unref(PickingOrdersList), {
                    key: 0,
                    "disabled-process": _unref(disabledProcess),
                    "is-search-active": _unref(isSearchActive),
                    "search-focused": _unref(searchFocused),
                    "show-banner": _unref(showBanner),
                    "filtering-status": _unref(filteringStatus)
                  }, null, 8, ["disabled-process", "is-search-active", "search-focused", "show-banner", "filtering-status"]))
                : (_openBlock(), _createBlock(_unref(HandoverOrdersList), {
                    key: 1,
                    "disabled-process": _unref(disabledProcess),
                    "is-search-active": _unref(isSearchActive),
                    "search-focused": _unref(searchFocused),
                    "show-banner": _unref(showBanner)
                  }, null, 8, ["disabled-process", "is-search-active", "search-focused", "show-banner"]))
            ]))
          : _createCommentVNode("", true),
        (_unref(isSearchActive))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_unref(SearchResultFeedback), {
                isSearching: _unref(isSearching),
                ordersAmount: _unref(searchedOrders).length,
                searchHintText: 
            _unref(isHandoverTab)
              ? _unref($t)('components.search-feedback.hint-handover.text')
              : _unref($t)('components.search-feedback.hint-picking.text')
          
              }, null, 8, ["isSearching", "ordersAmount", "searchHintText"]),
              _createVNode(_unref(SearchedOrderList), {
                isSearching: _unref(isSearching),
                "disabled-process": _unref(disabledProcess),
                "filtering-status": _unref(filteringStatus),
                searchedOrders: _unref(searchedOrders),
                pickingBuffer: _unref(pickingBuffer)
              }, null, 8, ["isSearching", "disabled-process", "filtering-status", "searchedOrders", "pickingBuffer"])
            ]))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _createVNode(_unref(CustomerNotePopup), {
      customerNote: _unref(interactedOrder)?.cartNote,
      isVisible: _unref(isNotePopupVisible),
      order: _unref(interactedOrder),
      onConfirm: _unref(toggleNote)
    }, null, 8, ["customerNote", "isVisible", "order", "onConfirm"]),
    (_unref(firstCheckedInOrder))
      ? (_openBlock(), _createBlock(_unref(CheckInBanner), {
          key: 0,
          "checkin-orders-length": _unref(checkinOrdersLength),
          context: _unref(CheckInBannerContextEnum).ORDER_OVERVIEW,
          order: _unref(firstCheckedInOrder),
          "show-banner": _unref(showBanner)
        }, null, 8, ["checkin-orders-length", "context", "order", "show-banner"]))
      : _createCommentVNode("", true),
    _createVNode(_unref(Dialog), {
      "cancel-text": _unref($t)('components.transmissions.orders-modal.cancel.text'),
      "confirm-text": _unref($t)('components.transmissions.orders-modal.confirm.text'),
      "content-text": _unref($t)('components.transmissions.orders-modal.content.text'),
      "title-text": _unref($t)('components.transmissions.orders-modal.title.text'),
      visible: _unref(isTransmissionsModalVisible),
      onCanceled: _unref(skipTransmission),
      onConfirmed: _cache[1] || (_cache[1] = () => _unref(toggleTransmissionsWindowVisibility)())
    }, null, 8, ["cancel-text", "confirm-text", "content-text", "title-text", "visible", "onCanceled"])
  ], 64))
}
}

})