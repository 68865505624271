import { computed, onMounted } from 'vue';
import { useOrders } from '@/features/orders/composables';
import { isPausedOrder } from '@/features/orders/helpers';
import { OrderLocalStatus, tabs } from '@/features/orders/types';
import { BooleanNumber } from '@/features/core/storage';

export const useHandoverOrders = () => {
  const { orders: handoverOrders, loadOrders: loadHandoverOrders } =
    useOrders();
  const { orders: checkedInOrders, loadOrders: loadCheckinOrders } =
    useOrders();

  const openOrders = computed(() => {
    return handoverOrders.value.filter(
      (item) => !isPausedOrder(item.localStatus),
    );
  });

  const checkedInOpenOrders = computed(() => {
    return checkedInOrders.value.filter(
      (item) => !isPausedOrder(item.localStatus),
    );
  });

  const defaultHandoverFilters = {
    temperatureClasses: undefined,
    sortBy: undefined,
    sort: undefined,
    status: tabs.Handover.status,
    localStatus: [tabs.Handover.localStatus, OrderLocalStatus.Paused],
  };

  onMounted(async () => {
    await Promise.all([
      loadCheckinOrders({
        ...defaultHandoverFilters,
        sortBy: 'checkIn.timestamp',
        isCheckedIn: BooleanNumber.True,
      }),
      loadHandoverOrders({
        ...defaultHandoverFilters,
        isCheckedIn: BooleanNumber.False,
      }),
    ]);
  });

  return {
    openOrders,
    checkedInOpenOrders,
  };
};
