import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {
  CheckedInOrderList,
  FallbackType,
  NoOrdersFallback,
} from '@/features/orders';
import { computed, toRefs } from 'vue';
import { useHandoverOrders } from '@/features/orders/composables/useHandoverOrders';
import OpenOrderList from '@/features/orders/components/OpenOrderList.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'HandoverOrdersList',
  props: {
  disabledProcess: {
    type: Boolean,
    default: false,
  },
  isSearchActive: {
    type: Boolean,
    default: false,
  },
  searchFocused: {
    type: Boolean,
    default: false,
  },
  showBanner: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props;

const { disabledProcess, isSearchActive } = toRefs(props);

const { openOrders, checkedInOpenOrders } = useHandoverOrders();

const isNoOrdersFallbackVisible = computed(
  () => !checkedInOpenOrders.value.length && !openOrders.value.length,
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_unref(isSearchActive))
      ? (_openBlock(), _createBlock(_unref(CheckedInOrderList), {
          key: 0,
          "checked-in-orders": _unref(checkedInOpenOrders),
          "disabled-process": _unref(disabledProcess)
        }, null, 8, ["checked-in-orders", "disabled-process"]))
      : _createCommentVNode("", true),
    _createVNode(OpenOrderList, {
      disabledProcess: _unref(disabledProcess),
      "open-orders": _unref(openOrders),
      ordersTitle: _ctx.$t('pages.order-overview.check-in-waiting.text')
    }, null, 8, ["disabledProcess", "open-orders", "ordersTitle"]),
    (!_unref(isSearchActive) && isNoOrdersFallbackVisible.value)
      ? (_openBlock(), _createBlock(_unref(NoOrdersFallback), {
          key: 1,
          fallbackType: _unref(FallbackType).noHandoverOrders
        }, null, 8, ["fallbackType"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})